import "leaflet/dist/leaflet.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import L from "leaflet";
import "leaflet.marker.slideto"; // Ensure this import is correct
import { MapContainer, Marker, Popup, TileLayer, Tooltip } from "react-leaflet";
import kapalIcon from "./assets/boat.png";
import markerBuilding from "./assets/marker-building.svg";
import serviceList from "./assets/note.svg";
import serviceListActive from "./assets/note-white.svg";
import route from "./assets/routing-2.svg";
import routeActive from "./assets/routing-2-white.svg";
import terminalInfo from "./assets/info-circle.svg";
import terminalInfoActive from "./assets/info-circle-white.svg";
import others from "./assets/menu.svg";
import othersActive from "./assets/menu-white.svg";
import axios from "axios";
import "leaflet-moving-rotated-marker";
import { useEffect, useRef, useState } from "react";

function App() {
  const urlAPI = "https://buppantun.pernika.net/api/";
  const wsPantun = "wss://nr.monitormyvessel.com/ws/pantun";
  const [monitor, setMonitor] = useState(null);
  const [terminal, setTerminal] = useState(null);
  const [tooltipVisible, setTooltipVisible] = useState(true);
  const [movingMarker, setMovingMarker] = useState([]);
  const [showServiceList, setShowServiceList] = useState(false);
  const [showRoute, setShowRoute] = useState(false);
  const [showTerminalInfo, setShowTerminalInfo] = useState(false);
  const [showOthers, setShowOthers] = useState(false);
  const [showContent, setShowContent] = useState(null);
  const [movingMarkerCustom, setMovingMarkerCustom] = useState({
    lat: 0,
    lng: 0,
    marker: L.marker([0, 0], {
      icon: L.divIcon({
        iconSize: [25, 35],
        popupAnchor: [2, -20],
        className: "target-ship-icon",
        html: `
  		<div style="position: relative; width: 60px; height: 60px; transform: translate(-16px, -12px);">
  			<div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); font-size: 20px; color: red;">
  				<div style="width: 2px; height: 14px; background-color: #2ED3B7; position: absolute; bottom: 16px;"></div>
  				<div style="width: 14px; height: 2px; background-color: #2ED3B7; position: absolute; right: 16px;"></div>
  				<div style="width: 2px; height: 14px; background-color: #2ED3B7; position: absolute; top: 16px;"></div>
  				<div style="width: 14px; height: 2px; background-color: #2ED3B7; position: absolute; left: 16px;"></div>
  			</div>
  		  <div style="border-radius: 50%; width: 100%; height: 100%; border: 1px solid #2ED3B7;"></div>
  		</div>`,
      }),
      rotationAngle: 90,
      rotationOrigin: "center center",
    }),
  });
  const ws = useRef(null);
  const movingMarkerRef = useRef(null);

  const [markerPosition, setMarkerPosition] = useState(null);

  useEffect(() => {
    const currentUrl = window.location.search;
    console.log(currentUrl);
    getCurrentAssetPosition(currentUrl);
  }, []);

  useEffect(() => {
    ws.current = new WebSocket(wsPantun);

    ws.current.onopen = () => {
      console.log("WebSocket connection established");
    };

    ws.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      const newPosition = [data.position?.lat, data.position?.lng];
      setMarkerPosition(newPosition);
      if (movingMarkerRef.current) {
        if (typeof movingMarkerRef.current.slideTo === "function") {
          movingMarkerRef.current.slideTo(newPosition, {
            duration: 1000, // duration of the slide in milliseconds
            keepAtCenter: true, // whether to keep the map centered on the marker
          });
          movingMarkerRef.current.setRotationAngle(data.heading || 0);
          movingMarkerRef.current.setRotationOrigin("center");
        } else {
          console.error("slideTo function is not available on the marker");
        }
      }
      console.log("WebSocket message received:", data);
    };

    ws.current.onclose = () => {
      console.log("WebSocket connection closed");
    };

    ws.current.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    // Cleanup on unmount
    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, []);

  const getCurrentAssetPosition = async (url) => {
    try {
      const response = await axios.get(`${urlAPI}detailbooking${url}`);
      let terminal = response.data?.data?.terminal;
      setTerminal(terminal);
      setMonitor(response.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const iconx = L.icon({
    iconSize: [25, 35],
    popupAnchor: [0, -15],
    iconUrl: kapalIcon,
  });

  const iconBuilding = L.icon({
    iconSize: [35, 45],
    popupAnchor: [0, -15],
    iconUrl: markerBuilding,
  });

  const showModal = (modal) => {
    setShowContent(modal);
    if (modal === "service-list") {
      setShowServiceList(!showServiceList);
      setShowRoute(false);
      setShowTerminalInfo(false);
      setShowOthers(false);
    } else if (modal === "route") {
      setShowRoute(!showRoute);
      setShowServiceList(false);
      setShowTerminalInfo(false);
      setShowOthers(false);
    } else if (modal === "terminal-info") {
      setShowTerminalInfo(!showTerminalInfo);
      setShowServiceList(false);
      setShowRoute(false);
      setShowOthers(false);
    } else if (modal === "others") {
      setShowOthers(!showOthers);
      setShowServiceList(false);
      setShowRoute(false);
      setShowTerminalInfo(false);
    }
  };

  function formatRupiah(number) {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
    }).format(number);
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 p-0">
          <MapContainer center={[-2.548926, 118.0148634]} zoom={6}>
            <TileLayer
              attribution=""
              url="https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}"
            />
            {terminal && (
              <Marker
                position={JSON.parse(terminal?.coordinates)}
                icon={iconBuilding}
              >
                <Popup
                  minWidth={150}
                  maxWidth={150}
                  eventHandlers={{
                    add: () => setTooltipVisible(false),
                    remove: () => setTooltipVisible(true),
                  }}
                >
                  <div className="mb-2">
                    <div className="fw-bold">Lokasi</div>
                    <div>{terminal?.location}</div>
                  </div>
                  <div className="mb-2">
                    <div className="fw-bold">Alamat</div>
                    <div>{terminal?.address}</div>
                  </div>
                  <div className="mb-0">
                    <div className="fw-bold">Tipe Terminal</div>
                    <div>{terminal?.terminal_type}</div>
                  </div>
                </Popup>
                {tooltipVisible && (
                  <Tooltip permanent opacity={1}>
                    <b>{terminal?.location}</b>
                  </Tooltip>
                )}
              </Marker>
            )}

            {markerPosition && (
              <Marker
                position={markerPosition}
                icon={iconx}
                ref={(marker) => {
                  if (marker) {
                    movingMarkerRef.current = marker;
                    if (typeof marker.slideTo === "function") {
                      marker.slideTo(markerPosition, {
                        duration: 1000, // duration of the slide in milliseconds
                        keepAtCenter: true, // whether to keep the map centered on the marker
                      });
                    } else {
                      console.error(
                        "slideTo function is not available on the marker"
                      );
                    }
                  }
                }}
              >
                <Popup minWidth={150} maxWidth={150}>
                  <div className="mb-1">
                    <div className="fw-bold">Latitude</div>
                    <div>{markerPosition[0]}</div>
                  </div>
                  <div className="mb-1">
                    <div className="fw-bold">Longitude</div>
                    <div>{markerPosition[1]}</div>
                  </div>
                </Popup>
              </Marker>
            )}
            <div className="overlay-top_info">
              <div className="mb-1">
                <b>Booking Code</b>: {monitor?.booking_code}
              </div>
              <div className="mb-1">
                <b>No. PKK</b>: {monitor?.no_pkk}
              </div>
              <div className="mb-1">
                <b>Voyage Type</b>: {monitor?.voyage_type}
              </div>
              <div className="mb-1">
                <b>Price</b>: {monitor?.price}
              </div>
              <div className="mb-1">
                <b>Komoditi</b>: {monitor?.comodity} - {monitor?.comodity_code}
              </div>
              <div className="mb-0">
                Komoditi Volume : {monitor?.commodity_volume}{" "}
                {monitor?.commodity_unit}
              </div>
            </div>
          </MapContainer>
          <div className="overlay-summary_info">
            <div className="summary-info-wrapper">
              <div
                className={`summary-info-item ${
                  showServiceList ? "active" : ""
                }`}
                onClick={() => showModal("service-list")}
              >
                <div>
                  <img
                    src={showServiceList ? serviceListActive : serviceList}
                    alt="service-list"
                  />
                </div>
                <label>Service List</label>
              </div>
              <div
                className={`summary-info-item ${showRoute ? "active" : ""}`}
                onClick={() => showModal("route")}
              >
                <div>
                  <img src={showRoute ? routeActive : route} alt="route" />
                </div>
                <label>Route</label>
              </div>
              <div
                className={`summary-info-item ${
                  showTerminalInfo ? "active" : ""
                }`}
                onClick={() => showModal("terminal-info")}
              >
                <div>
                  <img
                    src={showTerminalInfo ? terminalInfoActive : terminalInfo}
                    alt="terminal-info"
                  />
                </div>
                <label>Terminal</label>
              </div>
              <div
                className={`summary-info-item ${showOthers ? "active" : ""}`}
                onClick={() => showModal("others")}
              >
                <div>
                  <img src={showOthers ? othersActive : others} alt="others" />
                </div>
                <label>Others</label>
              </div>
            </div>
          </div>

          {showServiceList && showContent === "service-list" && (
            <div className="modal-content_custom">
              <div className="modal-content_custom-body">
                <div>
                  <div className="d-flex justify-content-between mb-3">
                    <h5 className="fw-bold d-flex mb-0 align-items-center">
                      <span className="me-2 d-flex align-items-center">
                        <img src={serviceList} alt="service-list" />
                      </span>
                      Service List
                    </h5>
                    <div>
                      <button
                        className="btn btn-primary _btn-custom"
                        onClick={() => showModal("service-list")}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                  <div className="service-list">
                    {monitor?.services_through_booking_services?.length > 0 &&
                      monitor?.services_through_booking_services?.map(
                        (item, index) => (
                          <div className="service-item" key={index}>
                            <div className="mb-2 d-flex justify-content-between align-items-center">
                              <div>
                                <div className="service-item_main">
                                  {item.name}
                                </div>
                                <div className="service-item_status">
                                  Status :{" "}
                                  {monitor?.services[index]?.operator_response}
                                </div>
                              </div>
                            </div>
                            <div className="service-item_info">
                              <div className="service-item_info-left">
                                <b>Tipe Harga</b>
                                <div>{item.price_type}</div>
                              </div>
                              <div className="service-item_info-right">
                                <b>Tipe Service</b>
                                <div className="service-item_label">
                                  {item.service_type}
                                </div>
                              </div>
                            </div>
                            <div className="service-item_info">
                              <div className="service-item_info-left">
                                <b>Start Date</b>
                                <div>{monitor?.services[index].start}</div>
                              </div>
                              <div className="service-item_info-right">
                                <b>End Date</b>
                                <div>{monitor?.services[index].end}</div>
                              </div>
                            </div>
                            <div className="service-item_info">
                              <div className="service-item_info-left">
                                <b>Duration</b>
                                <div>{monitor?.services[index].duration}</div>
                              </div>
                              <div className="service-item_info-right">
                                <b>Base Price</b>
                                <div>{formatRupiah(item.base_price)}</div>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                  </div>
                  {/* <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>Custom Price</th>
                          <th>Operator Response</th>
                          <th>Duration</th>
                          <th>Start Service</th>
                          <th>End Service</th>
                          <th>Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {monitor?.services?.length > 0 &&
                          monitor?.services?.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item.custom_price}</td>
                              <td>{item.operator_response}</td>
                              <td>{item.duration}</td>
                              <td>{item.start}</td>
                              <td>{item.end}</td>
                              <td>{item.price}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div> */}
                </div>
              </div>
            </div>
          )}

          {showRoute && showContent === "route" && (
            <div className="modal-content_custom">
              <div className="modal-content_custom-body">
                <div>
                  <div className="d-flex justify-content-between mb-3">
                    <h5 className="fw-bold d-flex mb-0 align-items-center">
                      <span className="me-2 d-flex align-items-center">
                        <img src={route} alt="route" />
                      </span>
                      Route
                    </h5>
                    <div>
                      <button
                        className="btn btn-primary _btn-custom"
                        onClick={() => showModal("route")}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                  <div className="route-info">
                    <div className="mb-4">
                      <div className="fw-bold mb-3">Pelabuhan Asal</div>
                      <div className="route-info_card">
                        <div className="d-flex">
                          <div className="route-info_section">
                            <b>Nama Pelabuhan</b>
                            <div>{monitor?.port_origin?.nama_pelabuhan}</div>
                          </div>
                          <div className="route-info_section">
                            <b>Kode Pelabuhan</b>
                            <div>{monitor?.port_origin?.kode_pelabuhan}</div>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="route-info_section">
                            <b>Kabupaten/Kota</b>
                            <div>{monitor?.port_origin?.nama_kabkota}</div>
                          </div>
                          <div className="route-info_section">
                            <b>Provinsi</b>
                            <div>{monitor?.port_origin?.nama_provinsi}</div>
                          </div>
                        </div>
                        <div className="d-flex mb-3">
                          <div className="route-info_section">
                            <b>Penyelenggara</b>
                            <div>{monitor?.port_origin?.penyelenggara}</div>
                          </div>
                          <div className="route-info_section">
                            <b>Unit Kerja</b>
                            <div>{monitor?.port_origin?.unitkerja}</div>
                          </div>
                        </div>
                        {/* <div>
                          <div>Fungsi Pelabuhan</div>
                          <div>{monitor?.port_origin?.fungsipelabuhan}</div>
                        </div> */}
                        <div className="separator"></div>
                        <div className="d-flex">
                          <div className="route-info_section w-100">
                            <b>Alamat Kantor</b>
                            <div>{monitor?.port_origin?.alamatkantor}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-0">
                      <div className="fw-bold mb-3">Pelabuhan Tujuan</div>
                      <div className="route-info_card">
                        <div className="d-flex">
                          <div className="route-info_section">
                            <b>Nama Pelabuhan</b>
                            <div>
                              {monitor?.port_destination?.nama_pelabuhan}
                            </div>
                          </div>
                          <div className="route-info_section">
                            <b>Kode Pelabuhan</b>
                            <div>
                              {monitor?.port_destination?.kode_pelabuhan}
                            </div>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="route-info_section">
                            <b>Kabupaten/Kota</b>
                            <div>{monitor?.port_destination?.nama_kabkota}</div>
                          </div>
                          <div className="route-info_section">
                            <b>Provinsi</b>
                            <div>
                              {monitor?.port_destination?.nama_provinsi}
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mb-3">
                          <div className="route-info_section">
                            <b>Penyelenggara</b>
                            <div>
                              {monitor?.port_destination?.penyelenggara}
                            </div>
                          </div>
                          <div className="route-info_section">
                            <b>Unit Kerja</b>
                            <div>{monitor?.port_destination?.unitkerja}</div>
                          </div>
                        </div>

                        {/* <div className="route-info_section">
                          <div>Fungsi Pelabuhan</div>
                          <div>
                            {monitor?.port_destination?.fungsipelabuhan}
                          </div>
                        </div> */}
                        <div className="separator"></div>
                        <div className="d-flex">
                          <div className="route-info_section w-100">
                            <b>Alamat Kantor</b>
                            <div>{monitor?.port_destination?.alamatkantor}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {showTerminalInfo && showContent === "terminal-info" && (
            <div className="modal-content_custom">
              <div className="modal-content_custom-body">
                <div>
                  <div className="d-flex justify-content-between mb-3">
                    <h5 className="fw-bold d-flex mb-0 align-items-center">
                      <span className="me-2 d-flex align-items-center">
                        <img src={serviceList} alt="service-list" />
                      </span>
                      Terminal
                    </h5>
                    <div>
                      <button
                        className="btn btn-primary _btn-custom"
                        onClick={() => showModal("terminal-info")}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                  <div>
                    <div className="mb-2 terminal-info">
                      <b>Nama Perusahaan</b>
                      <div>{monitor?.terminal?.company_name}</div>
                    </div>
                    <div className="mb-2 terminal-info">
                      <b>Alamat Perusahaan</b>
                      <div>{monitor?.terminal?.address}</div>
                    </div>
                    <div className="mb-2 terminal-info">
                      <b>Lokasi</b>
                      <div>{monitor?.terminal?.location}</div>
                    </div>
                    <div className="mb-2 terminal-info">
                      <b>Latitude</b>
                      <div>{JSON.parse(monitor?.terminal?.coordinates)[0]}</div>
                    </div>
                    <div className="mb-2 terminal-info">
                      <b>Longitude</b>
                      <div>{JSON.parse(monitor?.terminal?.coordinates)[1]}</div>
                    </div>
                    <div className="mb-2 terminal-info">
                      <b>Issue Date</b>
                      <div>{monitor?.terminal?.issue_date}</div>
                    </div>
                    <div className="mb-2 terminal-info">
                      <b>Terminal Type</b>
                      <div>{monitor?.terminal?.terminal_type}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {showOthers && showContent === "others" && (
            <div className="modal-content_custom">
              <div className="modal-content_custom-body">
                <div>
                  <div className="d-flex justify-content-between mb-3">
                    <h5 className="fw-bold d-flex mb-0 align-items-center">
                      <span className="me-2 d-flex align-items-center">
                        <img src={others} alt="others" />
                      </span>
                      Others
                    </h5>
                    <div>
                      <button
                        className="btn btn-primary _btn-custom"
                        onClick={() => showModal("others")}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                  <div>
                    <div className="others-info mb-2 ">
                      <b>No. PPJ</b>
                      <div>{monitor?.booking_ppj?.no_ppj}</div>
                    </div>
                    <div className="others-info mb-2">
                      <b>No. BAPJ</b>
                      <div>{monitor?.booking_ppj?.no_bapj}</div>
                    </div>
                    <div className="others-info mb-2">
                      <b>Activity</b>
                      <div>{monitor?.activity}</div>
                    </div>
                    <div className="others-info mb-2">
                      <b>Vessel Name</b>
                      <div>{monitor?.barge?.vessel_name}</div>
                    </div>
                    <div className="others-info mb-2">
                      <b>Country</b>
                      <div>{monitor?.barge?.country}</div>
                    </div>
                    <div className="others-info mb-2">
                      <b>Vessel Type</b>
                      <div>{monitor?.barge?.vessel_type}</div>
                    </div>
                    <div className="others-info mb-2">
                      <b>Barge Callsign</b>
                      <div>{monitor?.barge?.callsign}</div>
                    </div>
                    <div className="others-info">
                      <b>Barge MMSI</b>
                      <div>{monitor?.barge?.mmsi}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
